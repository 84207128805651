@import "_reset.scss";
@import "_colors.scss";

html {
  height: 100%;
  font-size: 16px;
  scroll-behavior: smooth;
  scroll-snap-type: mandatory;
	scroll-snap-points-y: repeat(100vh);
	scroll-snap-type: y mandatory;

}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: rgba(0,0,0,.5);
  border: 3px solid rgba(0, 0, 0, 0);
  border-radius: 14px;
}


body {
  height: 100%;
  margin: 0;
  background: $yellow-mid;
  // background: $yellow-dark radial-gradient(
  //  circle,
  //  $yellow-bright 0%,
  //  $yellow-mid 50%,
  //  $yellow-dark 100%
  //  );
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: 'Roboto', sans-serif;
  overflow: overlay;
  overscroll-behavior: none !important;
}

h1 {
  font-weight: 300;
  font-size: 5rem;
}

section {
  align-items: flex-start;
  // border: 1px dashed red;
  display: flex;
  flex-direction: column;
  font-size: 7vmin;
  justify-content: flex-start;
  height: 100vh;
  pointer-events: none;
  position: relative;
  scroll-snap-align: start;
  padding: 10% 30%;
  z-index: 1;
}

// 'dg' = dat.gui
.dg.ac {
  z-index: 1 !important;
}

#threejs-stats {
  top: initial !important;
  bottom: 0;
}

#webgl-canvas {
  left: 0;
  position: fixed;
  top: 0;
}
