*, :before, :after {
  box-sizing: border-box;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
  border: 0;
  margin: 0;
  padding: 0;
}

footer, header, nav, section, main {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  border-radius: 0;
}

html {
  height: 100%;
  scroll-behavior: smooth;
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
  font-size: 16px;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-thumb {
  background-color: #00000080;
  background-clip: padding-box;
  border: 3px solid #0000;
  border-radius: 14px;
}

body {
  height: 100%;
  overflow: overlay;
  background: #ffa01b no-repeat fixed;
  margin: 0;
  font-family: Roboto, sans-serif;
  overscroll-behavior: none !important;
}

h1 {
  font-size: 5rem;
  font-weight: 300;
}

section {
  height: 100vh;
  pointer-events: none;
  scroll-snap-align: start;
  z-index: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10% 30%;
  font-size: 7vmin;
  display: flex;
  position: relative;
}

.dg.ac {
  z-index: 1 !important;
}

#threejs-stats {
  bottom: 0;
  top: initial !important;
}

#webgl-canvas {
  position: fixed;
  top: 0;
  left: 0;
}

/*# sourceMappingURL=index.3024ece1.css.map */
